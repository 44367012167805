<template>
  <div>
    <b-modal
      v-model="modalSendToConnection"
      modal
      title="RETURN CLIENT"
      size="xmd"
      modal-class="modal-primary"
      scrollable
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <ValidationObserver ref="form">
        <b-form class="p-1">
          <!-- Title Clients -->
          <b-row cols="1">
            <b-input-group prepend="Clients (Accounts)">
              <div
                class="
                  form-control
                  h-auto
                  bg-transparent
                  border-secondary
                  sms-container
                "
              >
                <template v-for="(item, index) in nameClient">
                  <span :key="index">
                    <span>{{ item.name + " (" + item.account + ")" }}</span>
                    <feather-icon
                      v-if="nameClient.length > 1"
                      class="text-danger cursor-pointer"
                      icon="MinusCircleIcon"
                      @click="deleteAccount(item.id)"
                    />&nbsp;
                  </span>
                </template>
              </div>
            </b-input-group>
          </b-row>
          <b-row cols="1">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group
                :class="moduleId != 4 ? 'mt-3' : ''"
                label-for="message"
                label="Description"
              >
                <b-form-textarea
                  id="message"
                  v-model="comment"
                  placeholder="Write a description"
                  rows="10"
                  maxlength="1000"
                  :state="errors[0] ? false : null"
                />
                <template #description>
                  <small tabindex="-1" class="form-text text-danger"
                    >Max: 1000 characters</small
                  >
                </template>
              </b-form-group>
            </ValidationProvider>
          </b-row>
        </b-form>
      </ValidationObserver>
      <template #modal-footer>
        <div class="d-flex justify-content-end mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            :disabled="comment == ''"
            @click="sendClient()"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <span v-else>Send</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple,
  },
  props: {
    modalClientToConnection: {
      type: Boolean,
    },
    nameClient: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      reason: "",
      comment: "",
      advisor_id: "",
      isLoading: false,
      modalSendToConnection: this.modalClientToConnection,
    };
  },
  created() {
    this.modalSendToConnection = true;
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose", true);
    },
    sendClient() {
      this.$emit("onSubmit", this.comment);
    },
  },
};
</script>