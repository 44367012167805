import { amgApi } from "@/service/axios";
class StatusChangeService {
  async passClientToConnection(params) {
    try {
        const response = await amgApi.post("/clients/pass-client-to-connection", params)
        return response;
    } catch (e) {
        throw e
    }
  }

  async searchClientsConnection(params) {
    try {
        const response = await amgApi.post("/clients/search-clients-connection", params)
        return response;
    } catch (e) {
        throw e
    }
  }

  async asignAdvisor(params) {
    try {
        const response = await amgApi.post("/clients/asign-advisor", params)
        return response;
    } catch (e) {
        throw e
    }
  }

  async showTrackingList(params) {
    try {
        const response = await amgApi.post("/clients/tracking-list-view-connection", params)
        return response;
    } catch (e) {
        throw e
    }
  }

}
export default new StatusChangeService();
