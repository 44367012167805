<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="$refs.refStatusChangeGrid.refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refStatusChangeGrid"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column NAME -->
          <template #cell(lead_name)="data">
            <router-link
              class
              :to="{
                name: 'credit-experts-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <div>{{ data.item.account }}</div>
            <div>{{ data.item.eeuu_state }}</div>
            <div>{{ data.item.lead_mobile }}</div>
            <div>
              <template
                v-if="
                  data.item.in_connection == 'CONNECTION' &&
                  data.item.client_status_connection == 'RECOVERED'
                "
              >
                <span style="position: relative">
                  <div class="status_circle bg-success"></div>
                </span>
                <span style="margin-left: 15px">Recovered</span>
                <br />
              </template>

              <template
                v-if="
                  data.item.in_connection == 'ADMINISTRATION' &&
                  data.item.client_status_connection == 'TRANSITION'
                "
              >
                <span style="position: relative">
                  <div class="status_circle bg-dark"></div>
                </span>
                <span style="margin-left: 15px">Sent</span>
                <br />
              </template>
              <template
                v-if="
                  data.item.in_connection == 'ORIGIN' &&
                  data.item.client_status_connection == 'REJECTED'
                "
              >
                <span style="position: relative">
                  <div class="status_circle bg-info"></div>
                </span>
                <span style="margin-left: 15px">Rejected</span>
                <br />
              </template>
              <template
                v-if="
                  data.item.in_connection == 'CONNECTION' &&
                  data.item.client_status_connection == 'RECYCLE'
                "
              >
                <span style="position: relative">
                  <div class="status_circle bg-warning"></div>
                </span>
                <span style="margin-left: 15px">Recycle</span>
                <br />
              </template>
              <template
                v-if="
                  data.item.in_connection == 'CONNECTION' &&
                  data.item.client_status_connection != 'RECOVERED' &&
                  data.item.client_status_connection != 'RECYCLE'
                "
              >
                <span style="position: relative">
                  <div class="status_circle bg-danger"></div>
                </span>
                <span style="margin-left: 15px">Connection</span>
                <br />
              </template>
            </div>
          </template>
          <!-- Column Fee and Charges -->
          <template #cell(charges)="data">
            <span>$ {{ data.value }}</span>
          </template>
          <!-- Column MP -->
          <template #cell(monthly_amount)="data">
            <span>$ {{ data.value }}</span>
          </template>
          <!-- Column LP -->
          <template #cell(last_payment)="data">
            <div style="list-style: none; position: relative">
              <span style="margin-left: 15px">
                {{ data.value == "-" ? "" : data.value }}
                <b-img
                  v-if="data.item.state_lp == 1"
                  :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                  style="
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                  "
                />
                <b-img
                  v-else
                  :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                  style="
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                  "
                />
              </span>
            </div>
          </template>
          <!-- Column PT -->
          <template #cell(type_payment)="data">
            <div>
              <status-payment :account="data.item" />
            </div>
          </template>
          <!-- column UT -->
          <template #cell(task)="data">
            <div style="position: relative">
              <feather-icon id="calendar" icon="CalendarIcon" size="20" />
              <div
                style="
                  position: relative;
                  bottom: 15px;
                  left: 6.7px;
                  font-size: 85%;
                  font-weight: bold;
                "
              >
                {{ data.value }}
              </div>
            </div>
          </template>
          <!-- column UT -->
          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobal }}</span>
          </template>
          <!-- Column ADVISOR -->
          <template #cell(advisor_name)="data">
            <div style="position: relative">
              <div style="margin-left: 5px">
                <feather-icon
                  id="icon"
                  :title="
                    data.item.advisor_state == 1
                      ? 'Active'
                      : data.item.advisor_state == 2
                      ? 'Busy'
                      : data.item.advisor_state == 3
                      ? 'Away'
                      : 'Offline'
                  "
                  icon="CircleIcon"
                  size="13"
                  :style="
                    data.item.advisor_state == 1
                      ? 'background:green'
                      : data.item.advisor_state == 2
                      ? 'background:orange'
                      : data.item.advisor_state == 3
                      ? 'background:red'
                      : 'background:#ccc'
                  "
                />
                {{ data.value }}
              </div>
            </div>
          </template>
          <!-- Column LN -->
          <template #cell(last_note)="data">
            <span
              :style="
                data.item.s_note <= 1
                  ? ''
                  : data.item.s_note <= 2
                  ? 'color: #FF8000'
                  : 'color:#FF0000'
              "
              >{{ data.value }}</span
            >
          </template>
          <!--          Column Access Credential-->
          <template #cell(account_id)="data">
            <div class="d-flex justify-content-center mr-2">
              <feather-icon
                icon="EyeIcon"
                style="cursor: pointer"
                size="18"
                @click="openModalTrackingList(data.item.account_id)"
              />
            </div>
          </template>
          <!-- Column SERVICES -->
          <template #cell(actions)="data">
            <div
              v-if="
                moduleId == 4 &&
                (currentUser.role_id == 1 || currentUser.role_id == 2)
              "
            >
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
                :disabled="data.item.in_connection == 'CONNECTION'"
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <template
                  v-if="
                    data.item.in_connection == 'ADMINISTRATION' &&
                    data.item.client_status_connection == 'TRANSITION'
                  "
                >
                  <!-- SendToConnection -->
                  <b-dropdown-item
                    v-b-tooltip.hover.left="'Send To Connection'"
                    @click="openModalSendClientToConnection(data.item)"
                  >
                    <feather-icon icon="SendIcon" />
                    <span class="align-middle ml-50">Send To Connection</span>
                  </b-dropdown-item>
                  <!-- Send To Origin Module -->
                  <b-dropdown-item
                    v-b-tooltip.hover.left="'Return To Module'"
                    @click="openModalSendClientToOriginModule(data.item)"
                  >
                    <feather-icon icon="CornerUpRightIcon" />
                    <span class="align-middle ml-50">Return To Module</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </div>
            <div
              v-if="
                moduleId != 4 &&
                (currentUser.role_id == 1 || currentUser.role_id == 2)
              "
            >
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
                :disabled="
                  data.item.client_status_connection != 'RECOVERED' &&
                  data.item.client_status_connection != 'REJECTED'
                "
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- returnToSocialNetwork -->
                <b-dropdown-item
                  v-if="
                    data.item.client_status_connection == 'RECOVERED' &&
                    data.item.reasigned_advisor == 0
                  "
                  v-b-tooltip.hover.left="'Assign Advisor'"
                  @click="openModalAssignToAdvisor(data.item)"
                >
                  <feather-icon icon="UserPlusIcon" />
                  <span class="align-middle ml-50">Assign Advisor</span>
                </b-dropdown-item>
                <!-- returnToSocialNetwork -->
                <b-dropdown-item
                  v-if="data.item.in_connection == 'ORIGIN'"
                  v-b-tooltip.hover.left="'Send To Administration'"
                  @click="openModalSendClientToConnection(data.item)"
                >
                  <feather-icon icon="CornerUpRightIcon" />
                  <span class="align-middle ml-50">Send To Administration</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template #cell(typedoc)="data">
            <span>{{ data.item.accounts[0].typedoc }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-send-client-to-connection
      v-if="showModalSendToConnection"
      :modal-client-to-connection="showModalSendToConnection"
      :name-client="name_clients_arr"
      :destiny="'CONNECTION'"
      @onClose="closeModalSendToConnection"
      @onSubmit="sendToConnection($event)"
    ></modal-send-client-to-connection>

    <modal-send-client-to-origin-module
      v-if="showModalSendClientToOriginModule"
      :modal-client-to-connection="showModalSendClientToOriginModule"
      :name-client="name_clients_arr"
      @onClose="closeModalSendToOriginModule"
      @onSubmit="sendClientToOriginModule($event)"
    ></modal-send-client-to-origin-module>

    <modal-assign-connection-to-advisor
      v-if="showModalAssignAdvisor"
      :modal-client-to-connection="showModalAssignAdvisor"
      :name-client="name_clients_arr"
      @onClose="closeModalAssignToAdvisor"
      @onSubmit="assignConnectionToAdvisor"
      :with-comment="true"
      :advisors="optAdvisors"
    ></modal-assign-connection-to-advisor>

    <modal-tracking-list
      v-if="showModalTrackingList"
      :data-table="dataTrackingList"
      :loading="loadingTrackList"
      @close="closeModalTrackingList"
    ></modal-tracking-list>
  </div>
</template>

<script>
// Table clients
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import fields from "@/views/credit-experts/views/status-change/data/fields.status-change.data";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import StatusChangeService from "@/views/credit-experts/views/status-change/status-change-service";
import FiltersClientsData from "@/views/credit-experts/views/status-change/data/filters.status-change.data";
import ModalSendClientToConnection from "@/views/credit-experts/views/clients/components/clients/modals/ModalSendClientToConnection.vue";
import ModalSendClientToOriginModule from "@/views/credit-experts/views/status-change/components/modals/ModalSendClientToOriginModule.vue";
import ModalAssignConnectionToAdvisor from "@/views/credit-experts/views/status-change/components/modals/ModalAssignConnectionToAdvisor.vue";
import ModalTrackingList from "@/views/credit-experts/views/status-change/components/modals/ModalTrackingList.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    FilterSlot,
    StatusPayment,
    StatusAccount,
    ModalSendClientToConnection,
    ModalSendClientToOriginModule,
    ModalAssignConnectionToAdvisor,
    ModalTrackingList,
  },
  data() {
    return {
      messageNotFound: "",
      showModalSendToConnection: false,
      filters: FiltersClientsData,
      fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "",
      sortDesc: true,
      startPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      client: {},
      items: [],
      event: null,
      showModalSendClientToOriginModule: false,
      name_clients_arr: [],
      optAdvisors: [],
      showModalAssignAdvisor: false,
      dataTrackingList: [],
      loadingTrackList: false,
      showModalTrackingList: false,
    };
  },
  async created() {
    await this.usersPrograms();
  },
  async mounted() {
    this.filters[2].options = [
      { text: "ALL", value: "" },
      {
        text: "CONNECTION",
        value: this.$route.meta.tabValue == 1 ? "LOYAL" : "HOLD",
      },
      { text: "SENT", value: "TRANSITION" },
      { text: "REJECTED", value: "REJECTED" },
      { text: "RECYCLE", value: "RECYCLE" },
      { text: "RECOVERED", value: "RECOVERED" },
    ];
  },
  methods: {
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        if (this.currentUser.role_id) {
          const data = await ClientService.usersPrograms(params);
          this.optAdvisors = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetAllFilters() {
      this.filters[0].model = null;
      this.filters[1].model = null;
      this.filters[2].model = null;
      this.filterPrincipal.model = "";
      this.$refs.refStatusChangeGrid.refresh();
    },
    numberFormatted(n) {
      // This function adds comma thousand separator to a number
      let [numberPart, decimalPart] = n.toString().split(".");
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return (
        numberPart.replace(thousands, ",") +
        (decimalPart ? "." + decimalPart : "")
      );
    },
    openModalSendClientToConnection(client) {
      let namecl = [];
      namecl.push({
        name: client.lead_name,
        id: client.account_id,
        account: client.account,
        status: client.status,
        client_id: client.client_id,
      });
      this.name_clients_arr = namecl;
      this.showModalSendToConnection = true;
    },
    openModalSendClientToOriginModule(client) {
      let namecl = [];
      namecl.push({
        name: client.lead_name,
        id: client.account_id,
        account: client.account,
        status: client.status,
        client_id: client.client_id,
      });
      this.name_clients_arr = namecl;
      this.showModalSendClientToOriginModule = true;
    },
    openModalAssignToAdvisor(client) {
      let namecl = [];
      namecl.push({
        name: client.lead_name,
        id: client.account_id,
        account: client.account,
        status: client.status,
        client_id: client.client_id,
      });
      this.name_clients_arr = namecl;
      this.showModalAssignAdvisor = true;
    },
    async openModalTrackingList(account_id) {
      try {
        this.dataTrackingList = [];
        const response = await StatusChangeService.showTrackingList({
          id: account_id,
        });
        if (response.status === 200) {
          this.dataTrackingList = response.data;
          this.loadingTrackList = true;
          this.showModalTrackingList = true;
        }
      } catch (e) {
        throw e;
      }
    },
    closeModalTrackingList() {
      this.loadingTrackList = false;
      this.showModalTrackingList = false;
    },
    async sendToConnection(comment) {
      this.addPreloader();
      this.name_clients_arr.forEach(async (item) => {
        let in_connection = item.status == 2 ? "ADMINISTRATION" : "CONNECTION";
        let status_connection = item.status == 2 ? "HOLD" : "LOYAL";
        try {
          let params = {
            client_id: item.client_id,
            client_account_id: item.id,
            in_connection: in_connection,
            status_connection: status_connection,
            reason_connection: "",
            advisor_id: "",
            comment: comment,
            user_id: this.currentUser.user_id,
          };
          const response = await ClientService.passClientToConnection(params);
          if (response.status == 200) {
            this.$swal({
              title: "Save Succesfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((res) => {
              if (res) {
                this.$refs.refStatusChangeGrid.refresh();
                this.removePreloader();
                this.showModalSendToConnection = false;
              }
            });
          }
        } catch (e) {
          throw e;
        }
      });
      this.removePreloader();
    },
    async sendClientToOriginModule(comment) {
      this.addPreloader();
      this.name_clients_arr.forEach(async (item) => {
        try {
          let params = {
            client_id: item.client_id,
            client_account_id: item.id,
            in_connection: "ORIGIN",
            status_connection: "REJECTED",
            reason_connection: "",
            advisor_id: "",
            comment: comment,
            user_id: this.currentUser.user_id,
          };
          const response = await ClientService.passClientToConnection(params);
          if (response.status == 200) {
            this.$swal({
              title: "Save Succesfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((res) => {
              if (res) {
                this.$refs.refStatusChangeGrid.refresh();
                this.removePreloader();
                this.showModalSendClientToOriginModule = false;
              }
            });
          }
        } catch (e) {
          throw e;
        }
      });
      this.removePreloader();
    },
    async assignConnectionToAdvisor(advisor_id, comment) {
      this.addPreloader();
      this.name_clients_arr.forEach(async (item) => {
        try {
          let params = {
            account_id: item.id,
            advisor_id: advisor_id,
            comment: comment,
            user_id: this.currentUser.user_id,
          };
          const response = await ClientService.asignAdvisor(params);
          if (response.status == 200) {
            this.$swal({
              title: "Save Succesfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Continue",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((res) => {
              if (res) {
                this.$refs.refStatusChangeGrid.refresh();
                this.removePreloader();
                this.showModalAssignAdvisor = false;
              }
            });
          }
        } catch (e) {
          throw e;
        }
      });
      this.removePreloader();
    },
    closeModalSendToConnection() {
      this.showModalSendToConnection = false;
    },
    closeModalSendToOriginModule() {
      this.showModalSendClientToOriginModule = false;
    },
    closeModalAssignToAdvisor() {
      this.showModalAssignAdvisor = false;
    },
    async myProvider(ctx) {
      try {
        const params = {
          lead_name: this.filterPrincipal.model,
          in_connection: "",
          client_connection_state: this.filters[2].model,
          client_mobile: "",
          date_from: this.filters[0].model,
          date_to: this.filters[1].model,
          advisor_code: "",
          role_id: "",
          client_state: "",
          change_status: this.$route.meta.tabValue,
          page: ctx.currentPage,
          per_page: ctx.perPage,
        };
        const data = await ClientService.searchClientsConnection(params);
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        return this.items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      this.fields[13].visible = this.$route.meta.tabValue == 2;
      return this.fields.filter((item) => item.visible === true);
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.status_circle {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 15px !important;
}
</style>