<template>
  <b-modal
    title="Tracking List"
    v-model="openModal"
    no-close-on-backdrop
    no-close-on-esc
    header-class="b-vue-modal-header"
    @hidden="closeModal"
    hide-footer
    centered
    size="xl"
  >
    <div class="text-center" v-if="!loading">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-table sticky-header striped hover :items="dataTable" :fields="fields" v-else>
      <template #top-row v-if="dataTable.length == 0">
        <td colspan="4" class="text-center">NO INFORMATION</td>
      </template>
      <template #cell(status_connection)="data">
        {{ data.item.status_connection }}
        <br />
        <span v-if="data.item.reason_connection != null">( {{data.item.reason_connection}} )</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    dataTable: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openModal: true,
      fields: [
        {
          key: "status_connection",
          label: "Status",
          thStyle: { width: "15%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "comment",
          label: "Comment",
          thStyle: { width: "35%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "advisor_name",
          label: "User",
          thStyle: { width: "15%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "Creation Date",
          thStyle: { width: "40%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close", true);
    },
  },
};
</script>
